@import "./src/colorDefinitions";

.import-list-button-container {
  background-color: $bold-button !important;
  margin-right: 10px !important;
}

.import-list-button-container.open {
  width: 230px;
  border-radius: 25px;
}

.import-list-container-open-mode {
  display: flex;
  align-items: center;

  .import-list-text {
    font-size: 10px;
    margin-right: 10px;
  }
  .import-list-text-field {
    width: 150px;

    input {
      background-color: $main-background;
    }
  }
}