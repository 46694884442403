@import "./src/colorDefinitions";


.item-text {
  text-align: end;
  margin-right: 10px;
  margin-left: 22px;

  width: 36%;
  word-wrap: break-word;
  span {
    direction: rtl;
    text-align: start;

  }
}

.is-selected-item{
  text-decoration: line-through;
}

.grocery-item-delete {
  margin-left: 5px !important;
  padding-left: 5px !important;
  margin-right: 7px !important;
}

.grocery-item-setting {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.list-item-container {
  button{
    margin-right: unset !important;
    margin-left: unset !important;
  }
  span{
    margin-right: -6px !important;
    margin-left: unset !important;
  }
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

.new-item {
  background-color: #91c4af
}