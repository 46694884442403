@import "./src/colorDefinitions";


.my-grocery-container {
  margin-top: 46px;
}

.add-new-grocery-list-button-container{
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 70px;
  left: 6px;
  width: 100vw;
}

@media only screen and (max-height: 550px) {
  .add-new-grocery-list-button-container{
    bottom: 10px;
  }
}

.add-new-grocery-list-button{
  background-color: $bold-button !important;
  margin-left: 10px !important;

  -webkit-animation: bounce .4s infinite alternate;
  -moz-animation: bounce .4s infinite alternate;
  animation: bounce .4s infinite alternate;

}

@-webkit-keyframes bounce {
  to { -webkit-transform: scale(1.2); }
}
@-moz-keyframes bounce {
  to { -moz-transform: scale(1.2); }
}
@keyframes bounce {
  to { transform: scale(1.2); }
}

.style-background-button{
  background-color: $sub-main-background;
  height: 126px !important;
  border-top: 90px solid #F5F2F2;
  border-right: 100vw solid transparent;
  position: absolute;
  top: calc(100vh - 214px);
  z-index: -1;
}

.orange-arrow-animation {
  height: 67%;
  position: fixed;
  bottom: 70px;
  left: -45px;
}