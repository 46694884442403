@import "./src/colorDefinitions";

.welcome-grocery-container {
  height: 100vh;
}

.grocery-item {
  background-color: $white;
  box-shadow: #bcbbbbb8 0 0 3px;
  border-radius: 20px;
  height: 45px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 70px;
  margin: 10px;
}

.my-grocery-header {
  height: 70%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-grocery-welcome {
  text-align: center;
  font-size: 30px;
}

.my-grocery-name {
  text-align: center;
  font-size: 30px;
}

.my-grocery-title {
  text-align: center;
  font-size: 25px;
}

.my-grocery-subTitle {
  text-align: center;
  font-size: 20px;
}

.my-grocery-logo-container {
  margin-top: 20px;
  text-align: center;
  img{
    width: 170px;
  }
}

.my-grocery-bottom-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  width: 100%;

  .large {
    padding: 30px !important;
    background-color: $bold-button !important;
  }

  .main-button {


    -webkit-animation: bounce .7s infinite alternate;
    -moz-animation: bounce .7s infinite alternate;
    animation: bounce .7s infinite alternate;


    @-webkit-keyframes bounce {
      to { -webkit-transform: scale(1.2); }
    }
    @-moz-keyframes bounce {
      to { -moz-transform: scale(1.2); }
    }
    @keyframes bounce {
      to { transform: scale(1.2); }
    }

  }

  .circle-button {
    background-color: $regular-button;
    border: none;
    color: white;
    padding: 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: $shadow 0 0 10px;

    svg {
      font-size: 2.5rem;
      color: $text-color;
    }
  }
  .my-grocery-logo-text {
    margin-top: 8px;
    font-size: 12px;
    text-align: center;
  }

  .main {
    font-size: 19px;
  }
}

.style-background-top {
  height: 15%;
  background-color: $sub-main-background;
  border-bottom: 85px solid $main-background;
  border-left: 100vw solid transparent;
}

.grocery-shop-info {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    border-radius: 13px;
    height: 55px;
    font-size: 21px;
    padding: 0px 47px;
    color: white;
    background-color: #49A97D !important;
    margin: 0px 10px;
  }
}