@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
body {
    margin: unset;
}

.app-container{
 margin: 8px;
}

* {
 font-family: 'Varela Round', sans-serif !important;
}
.App{direction:rtl}body{background-color:#F5F2F2;color:#444744;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}

.groceries-items-container {
    height: calc(100vh - 205px);
    overflow: scroll;
}

.my-list-container {
    margin-top: 46px;
    margin-right: 1px;
    margin-left: 1px;
}

.list-item-container {
    direction: ltr;
    padding-right: 11px !important;
    padding-left: 0px !important;
}
.category-header-container{display:flex;align-items:center;justify-content:space-between;direction:ltr}.category-header-right{display:flex;flex-direction:row-reverse;justify-content:inherit;align-items:center;width:100%;margin-left:10px}.category-header-right nav{margin:-7px}.category-header-right nav div{font-size:20px;white-space:nowrap;overflow:hidden}.category-header-right .MuiList-padding{padding-bottom:unset}.category-header-left{padding-left:16px}.list-expand-on-off div{color:black}

.item-text{text-align:end;margin-right:10px;margin-left:22px;width:36%;word-wrap:break-word}.item-text span{direction:rtl;text-align:start}.is-selected-item{text-decoration:line-through}.grocery-item-delete{margin-left:5px !important;padding-left:5px !important;margin-right:7px !important}.grocery-item-setting{margin-right:5px !important;margin-left:5px !important}.list-item-container{transition:background-color 2s ease-out}.list-item-container button{margin-right:unset !important;margin-left:unset !important}.list-item-container span{margin-right:-6px !important;margin-left:unset !important}.new-item{background-color:#91c4af}

.add-grocery-manually-modal{background-color:white;margin:15px;padding:5px 15px 15px 15px;display:flex;flex-direction:column;align-items:flex-start}.add-grocery-manually-modal .modal-send-button{margin-top:10px}

.edit-grocery-manually-modal{background-color:white;margin:15px;padding:5px 15px 15px 15px;display:flex;flex-direction:column;align-items:stretch}.edit-grocery-manually-modal .modal-send-button{margin-top:10px}

.amount-container{display:flex;align-items:center;flex-direction:row-reverse;width:60px;justify-content:space-between;opacity:0.5}.amount-container .plus-amount{color:#BBB9B9;font-size:28px}.amount-container .minus-amount{color:#BBB9B9;font-size:28px}.amount-container .amount-value{background-color:#BBB9B9;color:black;width:21px;text-align:center;border-radius:5px}.enable{opacity:1}

.header-container{display:flex;align-items:center;justify-content:space-between;margin-right:-4px;margin-bottom:5px}.header-container-right{display:flex;align-items:center;height:17px;width:40%;justify-content:space-between}.header-container-right button{margin-right:-12px !important}.header-container-right .mark-all-button svg{font-size:20px !important}.header-container-right .delete-all-button{font-size:30px !important}.header-container-right .set-back-button svg{font-size:30px !important}.header-container-left{display:flex;align-items:center}.delete-all-container{margin-left:2px}.set-back-button{margin-left:unset !important}.mark-all-button{transform:scale(1.5);padding:10px}.is-live-share-icon{font-size:2.2rem !important}.delete-all-button{direction:ltr;font-size:2.2rem !important}.delete-all-button.disabled{direction:ltr;color:grey}.are-u-sure-container .MuiSnackbarContent-action{margin:unset;padding:unset;width:100%;justify-content:space-around}.are-u-sure-container .MuiButton-startIcon{margin:unset}.are-u-sure-container button{direction:ltr}.update-live-sharing-container .loading .sync-icon{-webkit-animation:rotation 2s infinite linear !important;animation:rotation 2s infinite linear !important}@-webkit-keyframes rotation{from{transform:rotate(0deg)}to{transform:rotate(359deg)}}@keyframes rotation{from{transform:rotate(0deg)}to{transform:rotate(359deg)}}.update-live-sharing-button{direction:ltr;margin-left:10px !important;background-color:#1960ac !important}.last-update-list{direction:ltr;color:#49A97D;margin-right:2px}.last-update-list-container{display:flex;font-size:9px;margin-left:-11px;align-items:center;min-width:165px}.last-update-list-container .last-update-list-container-date{direction:ltr}.last-update-list-container svg{font-size:15px !important}

.input-container{display:flex;justify-content:center;align-items:center;height:78px}.input-auto-complete{flex-grow:1}.recording-text-field-container{flex-grow:1}.text-field-container{position:relative;display:flex;flex-direction:row-reverse;margin-bottom:15px}.text-field-container input{margin-left:30px !important}.text-field-add-grocery{position:absolute !important}.add-button-plus{margin-top:21px !important}.selected-plus{-webkit-animation:ZoomInOut 1s linear infinite;animation:ZoomInOut 1s linear infinite}@-webkit-keyframes ZoomInOut{0%,100%{bottom:0;transform:scale(1, 1)}50%{transform:scale(1.4, 1.4)}}@keyframes ZoomInOut{0%,100%{bottom:0;transform:scale(1, 1)}50%{transform:scale(1.4, 1.4)}}.record-container{background-color:#04AA6D;border:none;color:white;padding:8px;display:inline-block;font-size:16px;margin:4px 2px;border-radius:50%}.record-container.recording{transform:scale(1.5);background-color:red}.popper-mic-permissions{position:fixed;top:68px;width:298px;z-index:10001}.popper-mic-permissions div{background-color:#ff7e7e}input-field{margin-top:5px}

.app-navigator{height:52px;background-color:#F6F9F8 !important;margin-top:6px;margin-bottom:20px}.app-toolbar{height:100%;min-height:unset !important;display:flex;justify-content:space-between;align-items:center;margin-left:11px}.app-toolbar .MuiIconButton-colorInherit{color:#49A97D}.app-toolbar .app-toolbar-left{display:flex;justify-content:space-around;align-items:center}.app-toolbar .app-toolbar-left svg{font-size:37px}.app-toolbar .whats-up-share-button{margin-right:10px}.open-menu-button{margin-right:-2px !important}.open-menu-button svg{font-size:2.5rem !important}.set-todo-button{width:97px;margin-left:17px;background-color:#49A97D;color:white}

.menu-bar{direction:ltr}.menu-text{direction:rtl}.menu-item-container{display:flex;justify-content:space-between !important}.menu-item-container-exit{background-color:#ffc3c3 !important}

.buttons-actions-container{display:flex;justify-content:space-evenly}.buttons-actions-container button{border-radius:20px !important}.share-modal-main{font-size:30px}.modal-text-container{min-height:144px}.share-modal-title{margin-bottom:20px}.share-modal-name-title{margin-bottom:9px}.share-modal-link{overflow-wrap:anywhere}.share-live-list-button-modal{background-color:#F8FEFB !important}.share-live-list-button-modal span{display:flex;flex-direction:row-reverse;color:#49A97D}.share-live-list-button-modal span span{margin-left:7px !important;margin-right:0px !important}.copy-live-list-button-modal{background-color:#49A97D !important;direction:rtl !important}.copy-live-list-button-modal span{display:flex;flex-direction:row-reverse}.copy-live-list-button-modal span span{margin-right:3px !important}.modal-sharing-container{width:calc(100vw - 15px);background-color:#49A97D;border-radius:20px;padding-bottom:3px}.modal-sharing-container.show-qr-on{padding-top:5px;background-color:#F5F2F2}.modal-sharing-container.show-qr-on{display:flex;align-items:center;justify-content:center}.modal-top-buttons button{background-color:#E0E2E1;width:50%;box-shadow:unset !important}.modal-top-buttons .modal-share-button-clicked{background-color:#49A97D !important}.modal-top-buttons .black-text{color:black}.modal-body-container{background-color:#E0E2E1;margin:7px;border-radius:8px;padding-bottom:30px}.share-modal-sub-title{display:flex;justify-content:center;align-items:center;direction:ltr}.share-modal-sub-title-copy{margin-left:10px}.makeStyles-paper-2{height:unset !important}

.start-page-container{display:flex;flex-direction:column}.start-page-title{text-align:center;margin-top:60px}.start-page-enter-button{width:100%}.start-page-menu-item{display:flex;justify-content:space-between}.start-page-menu-item img{margin-right:10px}.select-icon{width:20px}

.my-grocery-container{margin-top:46px}.add-new-grocery-list-button-container{display:flex;justify-content:space-between;position:fixed;bottom:70px;left:6px;width:100vw}@media only screen and (max-height: 550px){.add-new-grocery-list-button-container{bottom:10px}}.add-new-grocery-list-button{background-color:#49A97D !important;margin-left:10px !important;-webkit-animation:bounce .4s infinite alternate;animation:bounce .4s infinite alternate}@-webkit-keyframes bounce{to{-webkit-transform:scale(1.2)}}@keyframes bounce{to{transform:scale(1.2)}}.style-background-button{background-color:#B1EFD2;height:126px !important;border-top:90px solid #F5F2F2;border-right:100vw solid transparent;position:absolute;top:calc(100vh - 214px);z-index:-1}.orange-arrow-animation{height:67%;position:fixed;bottom:70px;left:-45px}

.arrow-into-icon svg{font-size:2.5rem !important}.grocery-item{background-image:linear-gradient(to right, #49A97D 1%, white 38%);height:-webkit-max-content !important;height:-moz-max-content !important;height:max-content !important;min-height:60px}.grocery-item-right{display:flex;justify-content:space-around;align-items:center;width:25%}.grocery-item-left{display:flex;justify-content:space-around;align-items:center;width:80%}.grocery-item-title{width:60%}.noselect{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.grocery-item-total{color:#ACABAB;font-size:13px}.live-sharing-on{color:#49A97D}.live-sharing-off{color:rgba(0,0,0,0.26)}.info-make-live-sharing{width:24%;text-align:center;margin-top:-31px;color:#FBA906;-webkit-animation:MoveUpDown 1s linear infinite;animation:MoveUpDown 1s linear infinite;position:relative;right:8px}@-webkit-keyframes MoveUpDown{0%,100%{bottom:0}50%{bottom:10px}}@keyframes MoveUpDown{0%,100%{bottom:0}50%{bottom:10px}}[contenteditable]{-webkit-user-select:text;-moz-user-select:text;-ms-user-select:text;user-select:text}

.import-list-button-container{background-color:#49A97D !important;margin-right:10px !important}.import-list-button-container.open{width:230px;border-radius:25px}.import-list-container-open-mode{display:flex;align-items:center}.import-list-container-open-mode .import-list-text{font-size:10px;margin-right:10px}.import-list-container-open-mode .import-list-text-field{width:150px}.import-list-container-open-mode .import-list-text-field input{background-color:#F5F2F2}

.welcome-grocery-container{height:100vh}.grocery-item{background-color:#fff;box-shadow:#bcbbbbb8 0 0 3px;border-radius:20px;height:45px;display:flex;justify-content:space-around;align-items:center;height:70px;margin:10px}.my-grocery-header{height:70%;align-items:center;display:flex;flex-direction:column;justify-content:center}.my-grocery-welcome{text-align:center;font-size:30px}.my-grocery-name{text-align:center;font-size:30px}.my-grocery-title{text-align:center;font-size:25px}.my-grocery-subTitle{text-align:center;font-size:20px}.my-grocery-logo-container{margin-top:20px;text-align:center}.my-grocery-logo-container img{width:170px}.my-grocery-bottom-buttons{margin-top:20px;display:flex;flex-direction:row-reverse;justify-content:space-around;width:100%}.my-grocery-bottom-buttons .large{padding:30px !important;background-color:#49A97D !important}.my-grocery-bottom-buttons .main-button{-webkit-animation:bounce .7s infinite alternate;animation:bounce .7s infinite alternate}@-webkit-keyframes bounce{to{-webkit-transform:scale(1.2)}}@keyframes bounce{to{transform:scale(1.2)}}.my-grocery-bottom-buttons .circle-button{background-color:#F6F9F8;border:none;color:white;padding:17px;text-align:center;text-decoration:none;display:inline-block;margin:4px 2px;cursor:pointer;border-radius:50%;box-shadow:rgba(32,32,32,0.721569) 0 0 10px}.my-grocery-bottom-buttons .circle-button svg{font-size:2.5rem;color:#444744}.my-grocery-bottom-buttons .my-grocery-logo-text{margin-top:8px;font-size:12px;text-align:center}.my-grocery-bottom-buttons .main{font-size:19px}.style-background-top{height:15%;background-color:#B1EFD2;border-bottom:85px solid #F5F2F2;border-left:100vw solid transparent}.grocery-shop-info{margin-top:10px;width:100%;display:flex;justify-content:center}.grocery-shop-info button{border-radius:13px;height:55px;font-size:21px;padding:0px 47px;color:white;background-color:#49A97D !important;margin:0px 10px}

.share-modal-buttons-container{display:flex !important;justify-content:space-between}.share-modal-buttons-container button{border-right-color:unset !important;border-radius:unset !important}

.my-grocery-app-title{position:absolute;top:0;right:0}.lista-main-icon{height:61px;margin-right:-8px;margin-top:-5px}.list-move-back{position:absolute;top:15px;left:0;margin-left:16px;color:#49A97D}

.landing-page-container{display:flex;flex-direction:column;background-color:white;height:100vh;align-items:center}.landing-page-animation{margin-bottom:-145px;height:65%}.landing-page-tile{margin-top:85px;text-align:center;font-size:30px;-webkit-animation:fade 4s linear 1 forwards;animation:fade 4s linear 1 forwards}@-webkit-keyframes fade{0%,100%{opacity:0}50%{opacity:1}}@keyframes fade{0%,100%{opacity:0}50%{opacity:1}}

