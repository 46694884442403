.groceries-items-container {
    height: calc(100vh - 205px);
    overflow: scroll;
}

.my-list-container {
    margin-top: 46px;
    margin-right: 1px;
    margin-left: 1px;
}

.list-item-container {
    direction: ltr;
    padding-right: 11px !important;
    padding-left: 0px !important;
}