.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 78px;
}

.input-auto-complete {
  flex-grow: 1;
}

.recording-text-field-container {
  flex-grow: 1;

}

.text-field-container {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 15px;

  input {
    margin-left: 30px !important;
  }
}

.text-field-add-grocery {
  position: absolute !important;
}

.add-button-plus {
  margin-top: 21px !important;
}

.selected-plus {
  animation: ZoomInOut 1s linear infinite;
}
@keyframes ZoomInOut {
  0%, 100% {
    bottom: 0;
    transform: scale(1,1);

  }
  50% {
    //bottom: 5px;
    transform: scale(1.4,1.4);
  }
}


.record-container {
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 8px;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 50%;
}

.record-container.recording {
  transform: scale(1.5);
  background-color: red;
}

.popper-mic-permissions {
  position: fixed;
  top: 68px;
  width: 298px;
  z-index: 10001;

  div {
    background-color: #ff7e7e;
  }
}

input-field {
  margin-top: 5px;
}