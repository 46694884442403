@import "./src/colorDefinitions";

.arrow-into-icon {
  svg {
    font-size: 2.5rem !important;
  }
}

.grocery-item {
  background-image: linear-gradient(to right, $bold-button 1% , white 38%);
  height: max-content !important;
  min-height: 60px;
}

.grocery-item-right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 25%;
}

.grocery-item-left {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
}

.grocery-item-title  {
  width: 60%
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.grocery-item-total {
  color: #ACABAB;
  font-size: 13px;
}

.live-sharing-on {
  color: $bold-button;
}

.live-sharing-off {
  color: rgba(0, 0, 0, 0.26);;
}

.info-make-live-sharing {
  width: 24%;
  text-align: center;
  margin-top: -31px;
  color: $text-info-color;

  animation: MoveUpDown 1s linear infinite;
  position: relative;
  right: 8px;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}