.menu-bar {
  direction: ltr;
}

.menu-text {
  direction: rtl;
}

.menu-item-container {
  display: flex;
  justify-content: space-between !important;
}

.menu-item-container-exit {
  background-color: #ffc3c3 !important;
}