@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import "./src/colorDefinitions";

.App {
    direction: rtl;
}

body{
    background-color: $main-background;
    color: $text-color;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}