.landing-page-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  align-items: center;
}

.landing-page-animation {
  margin-bottom: -145px;
  height: 65%;
}

.landing-page-tile {
  margin-top: 85px;
  text-align: center;
  font-size: 30px;
  animation: fade 4s linear 1 forwards;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}