@import "./src/colorDefinitions";

.app-navigator {
  height: 52px;
  background-color: #F6F9F8 !important;
  margin-top: 6px;
  margin-bottom: 20px;
}

.app-toolbar {
  height: 100%;
  min-height: unset !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 11px;

  .MuiIconButton-colorInherit {
    color: $bold-button
  }

  .app-toolbar-right {

  }

  .app-toolbar-left {
    display: flex;
    justify-content: space-around;
    align-items: center;

    svg {
      font-size: 37px;
    }
  }

  .whats-up-share-button {
    margin-right: 10px;
  }

}

.open-menu-button {
  margin-right: -2px !important;
  svg {
    font-size: 2.5rem !important;
  }
}


.set-todo-button {
  width: 97px;
  margin-left: 17px;
  background-color: $bold-button;
  color: white;
}