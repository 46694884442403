.edit-grocery-manually-modal {
  background-color: white;
  margin: 15px;
  padding: 5px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .modal-send-button {
    margin-top: 10px;
  }
}