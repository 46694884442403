@import "./src/colorDefinitions";


.buttons-actions-container {
  display: flex;
  justify-content: space-evenly;

  button {
    border-radius: 20px !important;

  }
}

.share-modal-main {
  font-size: 30px;
}

.modal-text-container {
  min-height: 144px;
}

.share-modal-title {
  margin-bottom: 20px;
}

.share-modal-name-title {
  margin-bottom: 9px;
}

.share-modal-link {
  overflow-wrap: anywhere;
}

.share-live-list-button-modal {
  background-color: #F8FEFB !important;
  span {
    display: flex;
    flex-direction: row-reverse;
    color: $bold-button;

    span {
      margin-left: 7px !important;
      margin-right: 0px !important;
    }
  }

}

.copy-live-list-button-modal {
  background-color: $bold-button !important;
  direction: rtl !important;
  span {
    display: flex;
    flex-direction: row-reverse;

    span {
      margin-right: 3px !important;
    }
  }
}

.modal-sharing-container {
  width: calc(100vw - 15px);
  //height: 240px;
  background-color: #49A97D;
  border-radius: 20px;
  padding-bottom: 3px;
}

.modal-sharing-container.show-qr-on {
  padding-top: 5px;
  background-color: $main-background
}

.modal-sharing-container.show-qr-on {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-top-buttons {
  button {
    background-color: #E0E2E1;
    width: 50%;
    box-shadow: unset !important;
  }

  .modal-share-button-clicked {
    background-color: #49A97D !important;
  }

  .black-text {
    color: black;
  }
}

.modal-body-container {
  background-color: #E0E2E1;
  //height: 187px;
  margin: 7px;
  border-radius: 8px;
  padding-bottom: 30px;
}

.share-modal-sub-title {
  display: flex;
  justify-content: center;
  align-items: center;
  direction: ltr;
}

.share-modal-sub-title-copy {
  margin-left: 10px;
}

.qr-code-container {

}

.makeStyles-paper-2 {
  height: unset !important;
}