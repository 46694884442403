.start-page-container {
  display: flex;
  flex-direction: column;
}

.start-page-title {
  text-align: center;
  margin-top: 60px;
}

.start-page-enter-button {
  width: 100%;
}

.start-page-menu-item {
  display: flex;
  justify-content: space-between;

  img {
    margin-right: 10px;
  }
}

.select-icon {
  width: 20px;
}