$color-main: #57F2C6;
$main-background: #F5F2F2;
$sub-main-background: #B1EFD2;
$bold-button: #49A97D;
$regular-button: #F6F9F8;
$shadow: #202020B8;
$white: #FFFFFF;
$text-color: #444744;
$text-info-color: #FBA906;

