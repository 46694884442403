@import "./src/colorDefinitions";


.my-grocery-app-title {
  position: absolute;
  top: 0;
  right: 0;
}

.lista-main-icon {
  height: 61px;
  margin-right: -8px;
  margin-top: -5px;
}

.list-move-back {
  position: absolute;
  top: 15px;
  left: 0;
  margin-left: 16px;
  color: $bold-button;
}