@import "./src/colorDefinitions";

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -4px;
  margin-bottom: 5px;
}

.header-container-right {
  display: flex;
  align-items: center;
  height: 17px;
  width: 40%;
  justify-content: space-between;

  button {
    margin-right: -12px !important;
  }


  .mark-all-button {
    svg {
      font-size: 20px !important;

    }
  }

  .delete-all-button {
    font-size: 30px !important;
  }

  .set-back-button {
    svg {
      font-size: 30px !important;
    }
  }

}

.header-container-left {
  display: flex;
  align-items: center;
}

.delete-all-container {
  margin-left: 2px;
}

.set-back-button {
  margin-left: unset !important;
}

.mark-all-button {
  //font-size: 2.2rem !important;
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

.is-live-share-icon {
  font-size: 2.2rem !important;
}

.delete-all-button{
  direction: ltr;
  font-size: 2.2rem !important;
}

.delete-all-button.disabled{
  direction: ltr;
  color: grey;
}

.are-u-sure-container {
 .MuiSnackbarContent-action {
   margin: unset;
   padding: unset;
   width: 100%;
   justify-content: space-around;
 }
  .MuiButton-startIcon {
    margin: unset;
  }
  button {
    direction: ltr;
  }
}

.update-live-sharing-container {
  .loading {
    .sync-icon{
      animation: rotation 2s infinite linear !important;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}

.update-live-sharing-button {
  direction: ltr;
  margin-left: 10px !important;
  background-color: #1960ac !important;
}

.last-update-list {
  direction: ltr;
  color: $bold-button;
  margin-right: 2px;
}

.last-update-list-container {
  display: flex;
  font-size: 9px;
  margin-left: -11px;
  align-items: center;
  min-width: 165px;

  .last-update-list-container-date {
    direction: ltr;
  }

  svg {
    font-size: 15px !important;
  }
}