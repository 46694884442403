body {
    margin: unset;
}

.app-container{
 margin: 8px;
}

* {
 font-family: 'Varela Round', sans-serif !important;
}