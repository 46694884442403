.category-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: ltr;
}

.category-header-right {
  display: flex;
  flex-direction: row-reverse;
  justify-content: inherit;
  align-items: center;
  width: 100%;
  margin-left: 10px;

  nav {
    margin: -7px;

    div {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .MuiList-padding{
    padding-bottom: unset;
  }
}

.category-header-left {
  padding-left: 16px;
}

.list-expand-on-off {
  div {
    color: black;
  }
}