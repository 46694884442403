.amount-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 60px;
  justify-content: space-between;
  opacity: 0.5;

  .plus-amount {
    color: #BBB9B9;
    font-size: 28px;
  }

  .minus-amount {
    color: #BBB9B9;
    font-size: 28px;
  }

  .amount-value {
    background-color: #BBB9B9;
    color: black;
    width: 21px;
    text-align: center;
    border-radius: 5px;
  }
}

.enable {
  opacity: 1;
}



